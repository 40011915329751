import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { Row, Col, Form, Card, Typography } from 'antd';

import { Button, Label as Input, BasicModal as Modal, Numeric, Collapse } from '@luxe/components';
import { createRiskIndex, trackRiskModelChange, updateRiskIndex } from 'admin/riskmodels/modules/riskmodels';

import RiskIndexComponent from './RiskIndexComponent';
import TotalCounter from './TotalCounter';

const { Text } = Typography;
const MODAL_TYPES = { EDIT: 'edit', CREATE: 'create' };

const RiskModelIndex = ({ label, total, indices, weight, indexArray, isEditable, showWeightedScoreElement }) => {
  const dispatch = useDispatch();

  const proactiveScoring = useSelector(store => store.accountOrg)?.accountOrg?.prefs?.proactive?.scoring;
  const { RiskModelScores } = useSelector(store => store.riskModels);
  const [activePanel, setActivePanel] = useState(undefined);
  const [modalConfig, setModalConfig] = useState({ isVisibile: false, type: undefined });

  const { colors, space } = useTheme();
  const [form] = Form.useForm();

  const allRiskModelScores = useMemo(() => {
    return ((RiskModelScores || [])?.flatMap(scores => scores?.riskModelScores) || []).reduce(function(
      filtered,
      option,
    ) {
      if (option?.user_editable) {
        filtered.push(option?.name?.toLowerCase());
      }
      return filtered;
    },
    []);
  }, [RiskModelScores]);

  const modalFormFields = {
    [MODAL_TYPES.CREATE]: {
      title: 'Create a new Sub Index',
      okText: 'Create',
      onOk: values => {
        dispatch(createRiskIndex(indexArray, { ...values, indices: [], components: [] }));
      },
    },
    [MODAL_TYPES.EDIT]: {
      title: 'Edit Subrisk Index',
      okText: 'Save',
      onOk: values => {
        dispatch(updateRiskIndex(indexArray, values));
      },
    },
  };

  return proactiveScoring ? (
    <Card
      style={{
        backgroundColor: colors.black1,
        width: '100%',
        margin: '15px',
        border: 'none',
        minHeight: '48px',
      }}
      title={
        <Col span={24} style={{ lineHeight: '28px' }}>
          <Row align="middle" gutter={16}>
            <Col>
              <Text>{label}</Text>
            </Col>
          </Row>
        </Col>
      }
      extra={
        <Row style={{ width: '30vw', lineHeight: '28px' }}>
          {showWeightedScoreElement() && (
            <Col span={4} push={8}>
              <TotalCounter total={total} />
            </Col>
          )}
          {showWeightedScoreElement() && (
            <Col span={14} push={6}>
              <Row gutter={16} justify="center" align="middle" style={{ justifyContent: 'flex-end' }}>
                <Col>
                  <Text>Total Weight, %</Text>
                </Col>
                <Col>
                  <Numeric
                    name="total"
                    value={weight}
                    onChange={value => {
                      dispatch(trackRiskModelChange(true));
                      dispatch(updateRiskIndex(indexArray, { weight: parseInt(value) }));
                    }}
                    style={{ width: '65px' }}
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      }
      headStyle={{
        backgroundColor: colors.BG1,
        borderColor: colors.black2,
        color: 'rgba(255,255,255,0.85)',
        lineHeight: '28px',
        fontSize: '20px',
        padding: '0 15px',
      }}
      bodyStyle={{ padding: `${space.small}px`, backgroundColor: colors.black1 }}
    >
      <Collapse
        accordion="true"
        expandIconPosition="right"
        defaultActiveKey={['']}
        style={{ margin: 'auto' }}
        onChange={e => {
          setActivePanel(e);
        }}
        activeKey={activePanel}
      >
        {indices?.map((x, i) => (
          <RiskIndexComponent
            key={x.key}
            weight={x.weight}
            label={x.name}
            componentTotal={x.componentTotal}
            indices={x.indices}
            components={x.components}
            indexArray={x.indexArray}
            total={x.total}
            activePanel={activePanel}
            index={i}
            isEditable={isEditable}
            showWeightedScoreElement={showWeightedScoreElement}
          />
        ))}
      </Collapse>
      {isEditable && (
        <Row>
          <Button
            variant="link"
            type="link"
            onClick={() => {
              setModalConfig({ visible: true, type: MODAL_TYPES.CREATE });
            }}
          >
            Add New Sub Index
          </Button>
        </Row>
      )}
      <Modal
        visible={modalConfig.visible}
        title={modalFormFields[modalConfig.type]?.title}
        okText={modalFormFields[modalConfig.type]?.okText}
        cancelText="Cancel"
        onCancel={() => {
          form.resetFields();
          setModalConfig({ visible: false, type: undefined });
        }}
        onOk={() => {
          form.submit();
          dispatch(trackRiskModelChange(true));
        }}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          name="risk_subrisk_index_form"
          initialValues={{
            modifier: 'public',
            name: modalConfig.type === MODAL_TYPES.EDIT ? label : '',
          }}
          onFinish={values => {
            modalFormFields[modalConfig.type].onOk(values);
            form.resetFields();
            setModalConfig({ visible: false, type: undefined });
          }}
          preserve={false}
        >
          <Form.Item
            name="name"
            label={<label style={{ color: 'white' }}>Name</label>}
            rules={[
              {
                required: true,
                message: 'Please input a title for the Subrisk Index!',
              },
              ...(label === 'Internal Risks'
                ? [
                    {
                      validator(rule, value) {
                        if (!allRiskModelScores.includes(value.toLowerCase())) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject('Please input a different title for the Subrisk Index!');
                        }
                      },
                    },
                  ]
                : []),
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  ) : null;
};

export default RiskModelIndex;
